<!--  -->
<template>
  <div class="dialogue">
    <Navigation />
    <div class="dialogue_content">
      <div class="dialogue_title">
        <h3>USER COMMENTS</h3>
        <h1>用户留言</h1>
      </div>
      <div class="Dialogue_chunk fixation">
        <div class="z Dialogue_content_info">
          <h3 class="z">请填写您的需要，我们会尽快给您回复！</h3>
          <p>
            PLEASE FILL IN YOUR NEEDS, WE WILL GET BACK TO YOU AS
            SOONASPOSSIBLE!
          </p>
        </div>

        <div class="Dialogue_content_ipt">
          <div>
            <el-input v-model="name">
              <template slot="prepend">姓名：</template>
            </el-input>
          </div>
          <div>
            <el-input v-model="phone">
              <template slot="prepend">电话：</template>
            </el-input>
          </div>
          <div>
            <el-input v-model="verify">
              <template slot="prepend">验证码：</template>
              <template slot="append"
                ><refreshCode ref="refresh" class="verifyCode"
              /></template>
            </el-input>
          </div>
        </div>
        <div class="Dialogue_content_txt">
          <el-input
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 4 }"
            placeholder="请输入您的需求"
            v-model="textarea1"
          >
          </el-input>
        </div>
        <el-button class="submit" @click="gosubmit">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../../components/Navigation.vue";
import { Message } from "element-ui";
import refreshCode from "../../components/refreshCode.vue";
export default {
  name: "Home",
  components: { refreshCode, Navigation },
  data() {
    return {
      name: "",
      phone: "",
      verify: "",
      textarea1: "",
    };
  },
  methods: {
    gosubmit() {
      let nameReg = /\S+/; //姓名不能为空
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/; //手机号
      if (!nameReg.test(this.name)) {
        Message({
          message: "警告哦，姓名不能为空",
          type: "warning",
        });
      } else if (!myreg.test(this.phone)) {
        Message({
          message: "警告哦，手机号格式错误",
          type: "warning",
        });
      } else if (this.verify !== this.$refs.refresh.identifyCode) {
        this.$refs.refresh.makeIdentifyCode({ randomTypeLen: true });
        Message({
          message: "警告哦，验证码错误",
          type: "warning",
        });
      } else if (!nameReg.test(this.textarea1)) {
        Message({
          message: "警告哦，需求不能为空",
          type: "warning",
        });
      } else {
        //网络请求
        this.$api
          .getGuestBook({
            userName: this.name,
            phone: this.phone,
            message: this.textarea1,
          })
          .then((res) => {
            // console.log(res);
            if (res.code === 0) {
              Message({
                message: "您的需求提交成功",
                type: "success",
              });
              this.name = "";
              this.phone = "";
              this.textarea1 = "";
              this.verify = "";
            } else {
              Message({
                message: res.msg,
                type: "success",
              });
            }
          })
          .catch((err) => {
            Message.error("网络错误请稍后重试");
          });
      }
    },
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.dialogue_content {
  text-align: center;
  background-image: url("../../assets/img/Dialogue/banner_bg.jpg");
  background-size: 100% 3.5rem;
  background-repeat: no-repeat;
  .dialogue_title {
    padding-top: 1.2rem;
    color: #ffffff;
    padding-bottom: 0.6rem;
    h3 {
      font-size: 0.185rem;
      margin-bottom: 0.156rem;
      letter-spacing: 0.08rem;
    }
    h1 {
      font-size: 0.2rem;
      letter-spacing: 0.08rem;
    }
  }
  .Dialogue_chunk {
    width: 80%;
    box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin: 0 auto;
    margin-bottom: 0.7rem;
    .Dialogue_content_info {
      padding-top: 0.7rem;
      padding-bottom: 0.25rem;
      h3 {
        font-size: 32px;
        font-weight: 400;
        color: #222222;
        margin-bottom: 0.2rem;
      }
      p {
        font-size: 20px;
        font-weight: 800;
        color: #d7d7d7;
        letter-spacing: 2px;
      }
    }

    .Dialogue_content_ipt {
      display: flex;
      width: 75%;
      margin: 0 auto;
      div {
        background: #fff;
        border-radius: 5px;
        font-size: 20px;
        color: #222222;
        height: 0.303rem;
        /deep/ .el-input {
          input {
            height: 0.303rem;
            line-height: 0.303rem;
            background: #f2f2f2;
            border: 0px;
            padding: 0 0.1rem 0 0;
          }
          .el-input-group__prepend {
            background: #f2f2f2;
            border: 0px;
          }
          .el-input-group__append {
            background: #fff;
            border: 0px;
            padding-right: 0.06rem;
          }
          .el-input-group__prepend {
            padding-right: 0;
          }
        }
      }
      div:nth-child(2) {
        margin-left: 0.173rem;
        margin-right: 0.173rem;
      }
    }
    .Dialogue_content_txt {
      width: 75%;
      margin: 0 auto;
      margin-top: 33px;
      /deep/ .el-textarea {
        textarea {
          background: #f2f2f2;
          border: 0px;
          resize: none;
          padding: 20px;
          font-size: 20px;
        }
      }
    }
    .submit {
      width: 0.6rem;
      height: 0.35rem;
      background-color: #CD9967;
      color: #fff;
      font-size: 0.1rem;
      margin-top: 0.2rem;
      margin-bottom: 0.6rem;
      padding: 0;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 750px) {
  .dialogue {
    width: 100%;
    .dialogue_content {
      margin-top: 0.615rem;
      text-align: center;
      background-image: url("../../assets/img/h5/Dialogue/banner_bg.png");
      background-size: 100% 3.5rem;
      background-repeat: no-repeat;
      .dialogue_title {
        padding-top: 1.28rem;
        color: #ffffff;
        padding-bottom: 0.6rem;
        h3 {
          font-size: 0.24rem;
          margin-bottom: 0.15rem;
          letter-spacing: 0.1421rem;
        }
        h1 {
          font-size: 0.44rem;
        }
      }
      .Dialogue_chunk {
        width: 91%;
        box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        margin: 0 auto 0.7rem;
        .Dialogue_content_info {
          padding: 0.7rem 0.5rem 0.25rem;
          h3 {
            font-size: 0.32rem;
            font-weight: 400;
            color: #222222;
            margin-bottom: 0.2rem;
          }
          p {
            font-size: 0.12rem;
            font-weight: 800;
            color: #d7d7d7;
          }
        }

        .Dialogue_content_ipt {
          display: block;
          margin: 0 auto;
          padding: 0 0.5rem;
          div {
            background: #fff;
            border-radius: 5px;
            font-size: 0.28rem;
            color: #222222;
            height: 0.775rem;
            /deep/ .el-input {
              input {
                height: 0.775rem;
                line-height: 0.775rem;
                background: #f2f2f2;
                border: 0px;
                padding: 0 0.4rem 0 0;
              }

              .el-input-group__prepend {
                background: #f2f2f2;
                padding-right: 0.15rem;
                border: 0px;
              }
              .el-input-group__append {
                background: #fff;
                border: 0px;
                padding-left: 0.2rem;
              }
            }
          }
          div:nth-child(2) {
            margin: 0.2rem 0;
          }
        }
        .Dialogue_content_txt {
          padding: 0 0.5rem;
          margin: 0.35rem auto 0;
          /deep/ .el-textarea {
            textarea {
              height: 2.66rem;
              background: #f2f2f2;
              border: 0px;
              resize: none;
              padding: 0.2rem 0.4rem 0;
              font-size: 0.28rem;
            }
          }
        }
        .submit {
          width: 2.67rem;
          height: 0.76rem;
          background-color: #cc9966;
          color: #fff;
          font-size: 0.28rem;
          margin-top: 0.5rem;
          margin-bottom: 0.6rem;
          padding: 0;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .dialogue_content {
    text-align: center;
    background-image: url("../../assets/img/Dialogue/banner_bg.jpg");
    background-size: 100% 3.5rem;
    background-repeat: no-repeat;
    .dialogue_title {
      padding-top: 1.2rem;
      color: #ffffff;
      padding-bottom: 0.6rem;
      h3 {
        font-size: 0.2rem;
        margin-bottom: 0.156rem;
        letter-spacing: 14px;
      }
      h1 {
        font-size: 0.3rem;
      }
    }
    .Dialogue_chunk {
      width: 80%;
      box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      margin: 0 auto;
      margin-bottom: 0.7rem;
      .Dialogue_content_info {
        padding-top: 0.7rem;
        padding-bottom: 0.25rem;
        h3 {
          font-size: 0.243rem;
          font-weight: 400;
          color: #222222;
          margin-bottom: 0.2rem;
        }
        p {
          font-size: 0.115rem;
          font-weight: 800;
          color: #d7d7d7;
          letter-spacing: 2px;
        }
      }

      .Dialogue_content_ipt {
        margin: 0 0.3rem;
        div {
          background: #fff;
          border-radius: 5px;
          font-size: 0.115rem;
          color: #222222;
          margin-bottom: 0.12rem;
          /deep/ .el-input {
            input {
              height: 0.53rem !important;
              line-height: 0.53rem;
              background: #f2f2f2;
              font-size: 0.2rem;
              border: 0px;
              padding: 0 0.2rem 0 0;
            }
            .el-input-group__prepend {
              background: #f2f2f2;
              border: 0px;
              font-size: 0.2rem;
            }
            .el-input-group__append {
              background: #fff;
              border: 0px;
              .verifyCode {
                width: 0.6rem;
                margin-top: 0.08rem;
              }
            }
          }
        }
      }
      .Dialogue_content_txt {
        margin: 0 0.3rem;
        margin-top: 33px;
        /deep/ .el-textarea {
          textarea {
            background: #f2f2f2;
            border: 0px;
            resize: none;
            padding: 0.16rem;
            font-size: 0.2rem;
          }
        }
      }
      .submit {
        width: 1.3rem;
        height: 0.5rem;
        background-color: #cc9966;
        color: #fff;
        font-size: 0.2rem;
        margin: 0.4rem 0;
        padding: 0;
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1680px) {
  .dialogue_content {
    text-align: center;
    background-image: url("../../assets/img/Dialogue/banner_bg.jpg");
    background-size: 100% 3.5rem;
    background-repeat: no-repeat;
    .dialogue_title {
      padding-top: 1.2rem;
      color: #ffffff;
      padding-bottom: 0.6rem;
      h3 {
        font-size: 0.185rem;
        margin-bottom: 0.156rem;
        letter-spacing: 14px;
      }
      h1 {
        font-size: 0.2rem;
      }
    }
    .Dialogue_chunk {
      width: 80%;
      box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      margin: 0 auto;
      margin-bottom: 0.7rem;
      .Dialogue_content_info {
        padding-top: 0.7rem;
        padding-bottom: 0.25rem;
        h3 {
          font-size: 28px;
          font-weight: 400;
          color: #222222;
          margin-bottom: 0.2rem;
        }
        p {
          font-size: 18px;
          font-weight: 800;
          color: #d7d7d7;
          letter-spacing: 2px;
        }
      }

      .Dialogue_content_ipt {
        display: flex;
        width: 75%;
        margin: 0 auto;
        div {
          background: #fff;
          border-radius: 5px;
          font-size: 18px;
          color: #222222;
          height: 0.303rem;
          /deep/ .el-input {
            input {
              height: 0.303rem;
              line-height: 0.303rem;
              background: #f2f2f2;
              border: 0px;
              padding: 0 0.1rem 0 0;
            }
            .el-input-group__prepend {
              background: #f2f2f2;
              border: 0px;
            }
            .el-input-group__append {
              background: #fff;
              border: 0px;
              padding-right: 0.06rem;
            }
            .el-input-group__prepend {
              padding-right: 0;
            }
          }
        }
        div:nth-child(2) {
          margin-left: 0.173rem;
          margin-right: 0.173rem;
        }
      }
      .Dialogue_content_txt {
        width: 75%;
        margin: 0 auto;
        margin-top: 33px;
        /deep/ .el-textarea {
          textarea {
            background: #f2f2f2;
            border: 0px;
            resize: none;
            padding: 20px;
            font-size: 18px;
          }
        }
      }
      .submit {
        width: 0.6rem;
        height: 0.35rem;
        background-color: #cc9966;
        color: #fff;
        font-size: 0.1rem;
        margin-top: 0.2rem;
        margin-bottom: 0.6rem;
        padding: 0;
      }
    }
  }
}
</style>